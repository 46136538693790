<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: ["chartTitle", "dataSource", "totalOveride"],
  data() {
    return {
      chartData: [],
      total: 0,
      htmlLegend: null
    };
  },
  watch: {
    dataSource(val, oldval) {
      this.chartData = val;
      this.renderTheChart();
    }
  },
  computed: {
    percentageData() {
      let percentageData = [],
        numberData = this.chartData.map(element => {
          return element.value;
        });

      let totalNumber = numberData.reduce((total, number) => {
        return total + number;
      });

      percentageData = numberData.map(value => {
        return (value / totalNumber) * 100;
      });

      return percentageData;
    },
    title() {
      return this.chartTitle == undefined ? "" : this.chartTitle;
    },
    labels() {
      let labels = this.chartData.map(element => {
        return element.name;
      });
      return labels;
    },
    backgroundColors() {
      let colors = this.chartData.map(element => {
        return element.color;
      });
      return colors;
    },
    totalFigure() {
      if (this.totalOveride == undefined) {
        let numberData = this.chartData.map(element => {
          return element.value;
        });

        let totalNumber = numberData.reduce((total, number) => {
          return total + number;
        });
        return totalNumber;
      } else {
        return this.totalOveride;
      }
    }
  },
  mounted() {
    this.addPlugin({
      id: "centrevalueDisplay",
      beforeDraw: function(chart) {
        if (chart.config.options.elements.center) {
          //Get ctx from string
          var ctx = chart.chart.ctx;

          //Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || "Arial";
          var txt = centerConfig.text;
          var color = centerConfig.color || "#000";
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated =
            (sidePadding / 100) * (chart.innerRadius * 2);
          var centerFontsize = centerConfig.fontSize;
          //Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(10 * widthRatio);
          var elementHeight = chart.innerRadius * 2;

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight);

          //Set font settings to draw it correctly.
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          //Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      }
    });

    if (Array.isArray(this.dataSource)) {
      this.chartData = this.dataSource;
      this.renderTheChart();
    } else {
      console.log("API DATA ONLINE");
      //ajax/promises to grab data here to populate chartData data variable and then render chart using dataSource prop
      // axios.get(this.dataSource)
      // .then((response) => {
      //     this.chartData = response.data
      //     this.renderTheChart()

      // })
    }

    this.htmlLegend = this.generateLegend();
  },

  methods: {
    renderTheChart() {
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }

      this.renderChart(
        {
          datasets: [
            {
              backgroundColor: this.backgroundColors,
              data: this.percentageData,
              borderColor: ["rgba(255,255,255,0.4)", "rgba(34,139,34,0.4)"],
              borderWidth: [0, 0]
            }
          ],
          labels: this.labels
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "bottom"
          },
          title: {
            display: true,
            text: this.chartTitle,
            position: "top"
          },
          animation: {
            animateScale: true,
            animateRotate: true
          },
          elements: {
            center: {
              text: `${this.totalFigure}`,
              color: "#656565", // Default is #000000
              fontStyle: "Helvetica", // Default is Arial
              sidePadding: 20, // Defualt is 20 (as a percentage),
              fontSize: "1px"
            }
          },
          tooltips: {
            intersect: true,
            callbacks: {
              label: function(tooltipItem, data) {
                let allData = data.datasets[tooltipItem.datasetIndex].data,
                  tooltipLabel = data.labels[tooltipItem.index],
                  tooltipData = allData[tooltipItem.index];

                return tooltipLabel + ": " + Math.round(tooltipData) + "%";
              }
            }
          },
          plugins: {
            datalabels: {
              color: "#656565",
              font: {
                weight: "bold"
              },
              // anchor:'end',
              // align:'end',
              // offset: 5,
              formatter: (value, context) => {
                return Math.round(value) + "%";
              }
            }
          }
        }
      );
    }
  }
};
</script>

<style scoped>
</style>
